import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IoIosArrowForward } from "react-icons/io";

const OurCustomers = ({ heading, content, customers }) => {
	return (
		<section className="my-8">
			<Container>
				{heading && (
					<Row className="align-items-lg-center justify-content-lg-center">
						<Col lg={7} className="mb-4 mb-lg-0 text-center">
							<h2 className="my-0 text-light-black">{heading}</h2>
							<div
								className="text-light-black py-4"
								dangerouslySetInnerHTML={{ __html: content }}
							></div>
						</Col>
					</Row>
				)}
				<Row className="g-5">
					{customers &&
						customers.map((customerDetail, i) => (
							<Col md={6} xl={4} className="col-md-offset-2">
								<div
									className="rounded-5 text-center py-4 px-4 h-100"
									style={{
										border: `1px solid ${customerDetail.industryPostFields.industryCardInfo.cardColor}`,
									}}
								>
									<img
										src={
											customerDetail.industryPostFields.industryCardInfo
												.industryIcon.node.sourceUrl
										}
										width="48px"
										height="48px"
										className="mt-2"
									/>
									{/* <GatsbyImage
                    image={
                      customerDetail.industryPostFields.industryCardInfo
                        .industryIcon.node?.localFile
                        .childImageSharp?.gatsbyImageData
                    }
                    alt={customerDetail.industryPostFields.industryCardInfo
                      .industryIcon.node?.altText}
                    className="mt-2"
                    style={{ height: "48px", width: "48px" }}
                  /> */}
									<h3 className="text-center py-4 mb-0 text-light-black">
										{customerDetail.title}
									</h3>
									<div
										className={`d-flex align-items-lg-center flex-column flex-lg-row py-2 justify-content-center`}
									>
										<Button
											as={Link}
											to={`/industries/${customerDetail.slug}`}
											size="lg"
											className="px-4 py-3 me-1 montserrat-bold fs-6 mb-3 mb-lg-0 text-light-black"
											variant="secondary"
										>
											Find out more
										</Button>
										{customerDetail.industryPostFields.industryCardInfo
											.industryCaseStudyLink && (
											<Button
												as={Link}
												to={`/case-studies/${customerDetail.industryPostFields.industryCardInfo.industryCaseStudyLink.nodes[0].slug}`}
												size="lg"
												className="px-4 py-3 montserrat-bold fs-6 bg-transparent border-0 text-light-black"
											>
												Case Study
												<IoIosArrowForward className="fs-4 ms-2" />
											</Button>
										)}
									</div>
								</div>
							</Col>
						))}
				</Row>
			</Container>
		</section>
	);
};

export default OurCustomers;
