import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import CtaBanner from "../components/cta-banner";
import HeroBanner from "../components/hero";
import Layout from "../components/layout";
import OurCustomers from "../components/our-customers";

const IndustriesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "industries" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				industriesContent {
					industriesBannerSection {
						bannerSectionHeading
						bannerSectionSubHeading
						bannerSectionContent
						bannerSectionButton1 {
							title
							target
							url
						}
						bannerSectionButton2 {
							title
							target
							url
						}
						bannerSectionBackground {
							node {
								altText
								sourceUrl
							}
						}
					}
					industriesCards {
						selectIndustries {
							nodes {
								... on WpIndustry {
									title
									slug
									link
									industryPostFields {
										industryCardInfo {
											industryCaseStudyLink {
												nodes {
													slug
												}
											}
											industryIcon {
												node {
													altText
													sourceUrl
													localFile {
														childImageSharp {
															gatsbyImageData(
																formats: WEBP
																quality: 80
																transformOptions: {
																	cropFocus: CENTER
																	fit: COVER
																}
																layout: CONSTRAINED
																placeholder: BLURRED
															)
														}
													}
												}
											}
											cardColor
										}
									}
								}
							}
						}
					}
					industriesGetInTouch {
						getInTouchHeading
						getInTouchContent
						getInTouchBackground {
							node {
								altText
								sourceUrl
							}
						}
						getInTouchCta1 {
							title
							target
							url
						}
						getInTouchCta2 {
							title
							target
							url
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const { pageData } = data;

	const {
		pageData: { seoFields, industriesContent },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={
						industriesContent?.industriesBannerSection.bannerSectionHeading
					}
					tagline={
						industriesContent?.industriesBannerSection.bannerSectionSubHeading
					}
					content={
						industriesContent?.industriesBannerSection.bannerSectionContent
					}
					btnCta1={
						industriesContent?.industriesBannerSection.bannerSectionButton1
					}
					btnCta2={
						industriesContent?.industriesBannerSection.bannerSectionButton2
					}
					bgImage={
						industriesContent?.industriesBannerSection.bannerSectionBackground
							.node?.sourceUrl
					}
					twoColumn={true}
				/>
				<OurCustomers
					customers={
						industriesContent?.industriesCards?.selectIndustries?.nodes
					}
				/>
				<CtaBanner
					heading={industriesContent?.industriesGetInTouch.getInTouchHeading}
					content={industriesContent?.industriesGetInTouch.getInTouchContent}
					btnCta1={industriesContent?.industriesGetInTouch.getInTouchCta1}
					btnCta2={industriesContent?.industriesGetInTouch.getInTouchCta2}
					bgImage={
						industriesContent?.industriesGetInTouch.getInTouchBackground?.node
					}
					textPosition="center"
				/>
			</Layout>
		</>
	);
};

export default IndustriesPage;
